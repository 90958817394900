<template>
  <div id="recharge">
    <div class="inDev">
      <img src="@/assets/image/PersonalCenter/FinancialCenter/inDev.png" alt="">
      <div class="inDev_tip">该功能正在开发中，敬请期待！</div>
    </div>
<!--    <el-form :model="infoForm" :rules="rules" ref="infoForm" label-width="100px" class="demo-ruleForm">-->
<!--      <el-form-item label="账户余额："  prop="account_money">-->
<!--        <div class="account_money"><span>¥</span>{{infoForm.account_money}}</div>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="冻结余额："  prop="frozenAmount" v-if="frozenAmount != 0">-->
<!--        <div class="account_money"><span>¥</span>{{frozenAmount}}</div>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="充值金额："  prop="recharge_money">-->
<!--        <el-input placeholder="请输入充值金额" v-model="infoForm.recharge_money" ></el-input><span id="Yuan">元</span>-->
<!--        <div class="recharge_money_ground">-->
<!--          <div :class="['recharge_money_item',item === recharge_money_item?'moeny_item_acitve':'']"-->
<!--               v-for="(item,index) in recharge_money_ground" :key="index" @click="clickMoneyItem(item)">-->
<!--            <div id="recharge_money">{{item}}</div>-->
<!--            <div id="sell_price">售价：{{item}}元</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-form-item>-->
<!--    </el-form>-->
<!--    <div class="btn">-->
<!--      <div class="submit_btn" @click="submitForm('infoForm')">立即充值</div>-->
<!--    </div>-->
<!--    <el-dialog-->
<!--      title="扫码支付"-->
<!--      :visible.sync="centerDialogVisible"-->
<!--      custom-class="recharge"-->
<!--      width="400px"-->
<!--      top="27vh"-->
<!--      center>-->
<!--      <span>-->
<!--        <div class="recharge_pay_orderNum">订单编号：{{ dialogData.order_no }}</div>-->
<!--        <div class="recharge_pay_money">-->
<!--          <span class="recharge_pay_money_label">支付金额：</span>-->
<!--          <span class="recharge_pay_money_RMB">¥</span>-->
<!--          <span class="recharge_pay_money_text">{{ dialogData.price }}</span>-->
<!--        </div>-->
<!--        <div class="recharge_pay_page">-->
<!--          <div class="validity_period">二维码有效期：{{time}}秒</div>-->
<!--          <div class="QR_code">-->
<!--            <img class="QR_code_img" :src="dialogData.code" alt="">-->
<!--          </div>-->
<!--          <div class="recharge_pay_tips">请扫一扫完成支付</div>-->
<!--        </div>-->
<!--        <div class="recharge_pay_wait">等待支付中...</div>-->
<!--      </span>-->

<!--    </el-dialog>-->
  </div>
</template>
<script>
import {apiuserInfo, apiUserRecharge,apiRechargeInfo} from "@/request/API";

export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      dialogData:{},
      time:60,
      settime:'',
      centerDialogVisible:false,
      payState:false,
      recharge_money_ground:[
        '500','800','1000','1500','2000','2500','5000','10000'
      ],
      //冻结余额
      frozenAmount:0,
      //充值金额item:active
      recharge_money_item: '',
      //充值方式item:active
      recharge_money_method: 'alipay',
      //表单信息
      infoForm:{
        account_money:this.$store.state.userData.now_money,
        recharge_money:'',
      },
      //表单验证
      rules: {
        recharge_money:[
          { required: true, message: '请输入充值金额', trigger: 'blur' },
          { pattern:/(^[1-9]([0-9]{1,5})?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确的金额'}
        ]
      },
      inDev:0
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        this.centerDialogVisible = valid
        if (valid) {
          apiUserRecharge({
            amount: this.infoForm.recharge_money,
          }).then(res => {
            this.dialogData = res.data
          })
        }
      });
    },
    countdownTime(){
      this.time--
      if(this.time===0){
        this.centerDialogVisible = false
        return false
      }
    },
    getRechargeInfo(){
      apiRechargeInfo({
        orderNo:this.dialogData.order_no
      }).then(res=>{
        this.centerDialogVisible = false
        this.payState = true
        return false
      }).then(err=>{
        return true
      })
    },
    //充值金额 选项卡点击事件
    clickMoneyItem(item){
      this.infoForm.recharge_money = item
      this.recharge_money_item = item
    },
    /**
     * 获取 提现账户 信息
     */
    getBasicInfo(){
      apiuserInfo({
        action:'cash'
      }).then(res=>{
        this.infoForm.account_money = res.data.freeAmount
        this.frozenAmount = res.data.frozenAmount
      })
    },
    //计算开发倒计时天数
    getDevDays(dateString1,dateString2){
      var startDate = dateString1;
      var endDate = Date.parse(dateString2);
      if (startDate>endDate){
        return 0;
      }
      if (startDate==endDate){
        return 1;
      }
      var days=(endDate - startDate)/(1*24*60*60*1000);
      return  parseInt(days);
    }
  },
  mounted () {
    this.getBasicInfo()
  },
  watch: {
    //监听infoForm
    infoForm: {
      handler: function(value) {
        this.clickMoneyItem(value.recharge_money)
      },
      deep: true
    },
    centerDialogVisible(val, oldVal){//普通的watch监听
      if (val != oldVal) {
        if(this.centerDialogVisible){
          this.time = 60
          this.countdown = setInterval(this.countdownTime, 1000);
          this.settime = setInterval(this.getRechargeInfo, 3000);
        }else {
          clearInterval(this.settime)
          clearInterval(this.countdown)
        }
      }
    },
    payState(val, oldVal){
      if (val) {
        this.$message({
          type:"success",
          center:true,
          message:'充值成功'
        })
        this.infoForm.recharge_money = ''
        this.getBasicInfo()
      }
      this.payState = false
    }

  },
  computed: {
  }
}
</script>

<style lang="less">
  .recharge_pay{
    width: 400px;
    height: 526px;
    background: #FFFFFF;
    border-radius: 10px;
    position: relative;
    overflow: visible;
    .el-message-box__content{
      padding: 0;
      .recharge_pay_content{
        margin: 5px 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .recharge_pay_title{
          font-size: 18px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #5B5B5B;
          line-height: 25px;
          margin:  0 0 9px 0;
        }
        .recharge_pay_orderNum{
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #8F8F8F;
          line-height: 22px;
          margin:  0 0 10px 0;
        }
        .recharge_pay_money{
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0 0 20px 0;
          .recharge_pay_money_label{
            font-size: 16px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #5B5B5B;
            line-height: 22px;
          }
          .recharge_pay_money_RMB{
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #FCAD13;
            line-height: 28px;
          }
          .recharge_pay_money_text{
            font-size: 24px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #FCAD13;
            line-height: 33px;
          }
        }
        .recharge_pay_page{
          width: 300px;
          height: 300px;
          background: linear-gradient(180deg, #747F8B 0%, rgb(255, 255, 255) 100%);
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .validity_period{
            margin: 20px 0 12px 0;
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #FFFFFF;
            line-height: 22px;
          }
          .QR_code{
            width: 196px;
            height: 196px;
            overflow: hidden;
            //background: #FFFFFF;
            margin: 0 0 17px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            .QR_code_img{
              width: 120%;
              height: 120%;
              object-fit: cover;
              //background: black;
            }
          }
          .recharge_pay_tips{
            font-size: 16px;
            font-family: PingFangSC-Thin, PingFang SC;
            font-weight: 300;
            color: #5B5B5B;
            line-height: 22px;
          }
        }
        .recharge_pay_wait{
          margin: 25px 0 0 0 ;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #8F8F8F;
          line-height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #8F8F8F;
        }
      }
    }
  }
</style>

<style lang='less' scoped>
#recharge{
  //padding: 30px 0 0 77px;
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  justify-content: center;
  .inDev_tip{
    font-size: 14px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #9A9A9A;
    line-height: 20px;
    span{
      color: #FCAD13;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
    }
  }
  //align-items: center;
  /deep/.el-input{
    width: 250px;
  }
  /deep/.el-input__inner{
    width: 250px;
    height: 40px;
    background: #F9F9F9;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #8F8F8F;
  }
  /deep/.is-error .el-input__inner{
    border-color: #F56C6C;
  }
  /deep/.el-form-item__label{
    text-align: left;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    padding: 0 0 0 12px;
  }
  /deep/.is-required{
    height: 260px;
  }
  /deep/.is-required .el-form-item__label{
    padding: 0;
  }
  /deep/.el-form-item__content{
    margin-left: 0 !important;
  }
  /deep/.el-form-item__error{
    position: relative;
    margin-left: 100px;
  }
  //账户余额
  .account_money{
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #FCAD13;
    line-height: 40px;
    span{
      font-size: 20px;
      line-height: 28px;
    }
  }
  //充值金额 单位：元
  #Yuan{
    margin-left: 10px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 22px;
  }
  //充值金额 选项卡
  .recharge_money_ground{
    margin: 0 0 0 12px;
    position: absolute;
    top: 75px;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .recharge_money_item{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 189px;
      height: 80px;
      background: #F9F9F9;
      border-radius: 10px;
      border: 1px solid #E9E8E8;
      margin: 0 10px 10px 0;
      cursor: pointer;
      #recharge_money{
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #5B5B5B;
        line-height: 28px;
      }
      #sell_price{
        font-size: 14px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #8F8F8F;
        line-height: 20px;
      }
    }
    .moeny_item_acitve{
      background: #FFFCEE;
      border: 1px solid #FCAD14;
    }
  }

  //充值按钮
  .btn{
    margin: 29px 0 0 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .submit_btn{
      width: 200px;
      height: 40px;
      line-height: 40px;
      background: #FFDB51;
      border-radius: 28px;
      font-size: 18px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #000000;
      text-align: center;
      cursor: pointer;
    }
  }
  /deep/.el-dialog{
    border-radius: 10px;
  }
  /deep/.el-dialog__header{
    padding:30px 0 10px
  }
  /deep/.el-dialog__title{
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #5B5B5B;
    line-height: 25px;
  }
  /deep/.el-dialog__body{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 50px !important;
    span{
      display: flex;
      flex-direction: column;
      align-items: center;
      .recharge_pay_orderNum{
        font-size: 16px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #8F8F8F;
        line-height: 22px;
        margin: 0 0 10px 0;
      }
      .recharge_pay_money{
        display: flex;
        flex-direction: row;
        align-items:center;
        margin: 0 0 20px 0;
        .recharge_pay_money_label{
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #5B5B5B;
          line-height: 22px;
        }
        .recharge_pay_money_RMB{
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #FCAD13;
          line-height: 28px;
        }
        .recharge_pay_money_text{
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #FCAD13;
          line-height: 33px;
        }
      }
      .recharge_pay_page{
        width: 300px;
        height: 300px;
        background: linear-gradient(180deg, #747F8B 0%, rgb(255, 255, 255) 100%);
        border-radius: 10px;
        margin: 0 0 20px 0 ;
        display: flex;
        flex-direction: column;
        align-items: center;
        .validity_period{
          margin: 20px 0 12px 0;
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #FFFFFF;
          line-height: 22px;
        }
        .QR_code{
          width: 196px;
          height: 196px;
          overflow: hidden;
          //background: #FFFFFF;
          margin: 0 0 17px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          .QR_code_img{
            width: 120%;
            height: 120%;
            object-fit: cover;
            //background: black;
          }
        }
        .recharge_pay_tips{
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #5B5B5B;
          line-height: 22px;
        }
      }
      .recharge_pay_wait{
        font-size: 16px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #8F8F8F;
        line-height: 22px;
        margin: 0 0 36px 0 ;
      }
    }
  }

}
</style>
